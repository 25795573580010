
import { onMounted, ref, defineComponent } from "vue";
import Managepermission from "@/views/permissions/model/ManagePermission.vue";
import EditPermission from "@/views/permissions/model/EditPermission.vue";
import ManageFeatures from "@/views/permissions/model/ManageFeatures.vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getFeatureList,
  addNewPermission,
  addFeature,
} from "@/store/api/devices";
export default defineComponent({
  name: "Newpermission",
  components: { Managepermission, ManageFeatures, EditPermission },
  setup() {
    const feature_list = ref([]);
    const loading = ref(false);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    let payload = ref({
      feature_id: "",
      name: "",
      action: "",
    });

    onMounted(async () => {
      let res = await getFeatureList();
      feature_list.value = res.data;
    });

    const add_New_Permission = async (val) => {
      const re = await addNewPermission(val);
      console.log(re);
    };

    return {
      feature_list,
      payload,
      add_New_Permission,
      loading,
      createAPIKeyModalRef,
    };
  },
});
