<template>
  <!--begin::Card-->
  <div class="card border-0" style="width: 100%">
    <!--begin::Card header-->
    <div class="card-header border-0 mt-3" style="margin: -12px 0px">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <div class="h4 text-primary">Role Management</div>
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->

      <!--begin::Card toolbar-->
      <div
        class="card-toolbar"
        v-if="assigned_permissions.includes('create-role')"
      >
        <AddNewRole :msg="{ id: UserId, bolean: editRoleBolean }" />
      </div>
      <!--end::Card toolbar-->
    </div>

    <!--end::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0" style="width: 100%">
      <KTDatatable
        :table-data="roles"
        :total="total"
        :table-header="headerConfig"
        :enable-items-per-page-dropdown="true"
        :loading="loading"
        :rowsPerPage="pagination.page_size"
        @current-change="currentChange"
        @items-per-page-change="itemsPerPageChange"
      >
        <template v-slot:cell-id="{ row: roles }">
          <div>{{ roles.id }}</div>
        </template>
        <template v-slot:cell-name="{ row: roles }">
          <div>{{ roles.name }}</div>
        </template>

        <template v-slot:cell-action="{ row: roles }">
          <div class="d-flex justify-content-left">
            <a
              v-if="assigned_permissions.includes('update-role')"
              href="#"
              class="text-gray-600 text-hover-primary mx-1"
              title="Edit"
              @click="getUserId(roles)"
            >
              <div :class="`badge badge-light-success`">
                <i
                  class="fas fa-edit text-success"
                  data-bs-toggle="modal"
                  data-bs-target="#addusermodal"
                ></i>
              </div>
            </a>
            <a
              href="#"
              class="text-gray-600 text-hover-primary mx-1"
              title="Delete"
              v-if="assigned_permissions.includes('delete-role')"
              @click="delete_Exist_Role(roles.id)"
            >
              <div :class="`badge badge-light-success`">
                <i class="fas fa-trash text-danger"></i>
              </div>
            </a>
          </div>
        </template>
      </KTDatatable>
    </div>
    <hr class="mb-10" />
    <!--permission managment -->

    <div class="card-header border-0" style="margin: -12px 0px">
      <!--begin::Card title-->
      <div class="card-title">
        <!--begin::Search-->
        <div class="d-flex align-items-center position-relative my-1">
          <div class="h4 text-primary">Permission Management</div>
          <div>
            <select
              class="form-select border ms-5 border-primary form-select-solid"
              data-control="select2"
              @change="permissionByRole(assign_permission.role_id)"
              v-model="assign_permission.role_id"
              data-placeholder="Select an option"
              data-allow-clear="true"
            >
              <option disabled value="">Please Select Role</option>
              <option
                v-for="(role, index) in roles"
                :key="index"
                :value="role.id"
              >
                {{ role.name }}
              </option>
            </select>
          </div>
        </div>
        <!--end::Search-->
      </div>
      <!--begin::Card title-->

      <!--begin::Card toolbar-->
      <!--add new permission button-->
      <div
        class="card-toolbar"
        v-if="assigned_permissions.includes('create-permission')"
      >
        <Newpermission />
      </div>
      <!--end::Card toolbar-->
    </div>
    <!--permission managment title end  -->
    <!--permission body -->
    <div
      class="container permissions-check"
      style="padding-left: 0px !important"
    >
      <div class="row">
        <div
          class="col-lg-3 col-md-4 mt-5"
          v-for="(feature, index) in features_list"
          :key="index"
        >
          <div class="text-primary h6" style="font-size: 1.5rem">
            {{ feature.name }}
          </div>
          <div
            class="form-check form-check-custom form-check-solid form-check-sm"
          >
            <ul class="list-group w-250px">
              <template v-for="(permission, index) in permissions" :key="index">
                <li
                  style="display: flex"
                  class="list-group-item"
                  v-if="feature.id === permission.feature_id"
                >
                  <input
                    class="form-check-input border border-primary"
                    type="checkbox"
                    :value="permission.id"
                    id="flexRadioLg"
                    v-model="assign_permission.permission_id"
                    :checked="
                      assign_permission.permission_id.includes(permission.id)
                        ? 1
                        : 0
                    "
                  />
                  <span class="mx-3"> {{ permission.name }}</span>
                  <!-- <i
                    @click="unAssignPermission(permission.id)"
                    class="fas fa-times text-danger"
                    aria-hidden="true"
                  ></i>
                  <b-spinner
                    v-if="loading && permissionId == permission.id"
                    class="mt-2"
                    small
                    label="Small Spinner"
                  ></b-spinner> -->
                </li>
              </template>
            </ul>
          </div>
        </div>
      </div>
      <button
        v-if="assigned_permissions.includes('assign-permission')"
        class="btn btn-primary mt-4 mb-10"
        @click="assignpermission(assign_permission)"
      >
        Save
        <b-spinner
          v-if="assigpermission_loading"
          class="mt-2"
          small
          label="Small Spinner"
        ></b-spinner>
      </button>
    </div>
    <!--permission body end  -->
    <!--end::Card body-->
  </div>

  <!--end::Card-->
</template>

<script lang="s'ts">
import { defineComponent, ref, onMounted } from "vue";
import KTDatatable from "@/components/kt-datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Newpermission from "@/views/permissions/model/Newpermission.vue";
import {
  getCustomersList,
  deleteRole,
  assignPermission,
  getUserRole,
  permissionList,
  getFeatureList,
  permission_By_Role,
  unassignPermission,
  checkPassword,
} from "@/store/api/devices";
import { toInteger } from "lodash";
import AddNewRole from "@/views/permissions/model/AddNewRole.vue";

export default defineComponent({
  name: "repository",
  components: {
    KTDatatable,
    Newpermission,
    AddNewRole,
  },
  setup() {
    const assign_permission = ref({
      role_id: "",
      permission_id: [],
    });
    const role_data = ref([]);
    const permissions = ref([]);
    const features_list = ref([]);
    const roles = ref([]);
    const editRoleBolean = ref(false);
    const customers = ref([]);
    const search = ref("");
    const cardData = ref("");
    const loading = ref(false);
    const assigpermission_loading = ref(false);
    const permissionId = ref(false);
    const UserId = ref();
    const assigned_permissions = ref([]);
    const userDataforUpdate = ref([]);
    const headerConfig = ref([
      {
        name: "ID",
        key: "id",
      },
      {
        name: "Role",
        key: "name",
      },
      {
        name: "Action",
        key: "action",
      },
    ]);
    const total = ref(0);
    const pagination = ref({
      page_number: 1,
      customer_id: "",
      page_size: 5,
      filters: {
        name: "",
      },
    });
    onMounted(async () => {
      loading.value = true;
      let p = localStorage.getItem("permissions");
      assigned_permissions.value = JSON.parse(p);
      getCustomersList().then((response) => {
        customers.value = response;
        loadData();
      });
      let result = await getFeatureList();
      features_list.value = result.data;

      setCurrentPageBreadcrumbs("Role & Permissions", [""]);
    });
    const loadData = async () => {
      loading.value = true;
      let userlist = await getUserRole();
      roles.value = userlist.data;
      let resul = await permissionList();
      permissions.value = resul.data;
      total.value = userlist.data.total_number_of_records;
      loading.value = false;
    };
    const assignpermission = async (val) => {
      let roleId = val.role_id;
      let strValue = roleId.toString();
      val.role_id = strValue;
      assigpermission_loading.value = true;
      const result = await assignPermission(val)
        .then((res) => {
          if (res.success === true) {
            Swal.fire({
              text: `Successfully Assign Permission`,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
          }
          assigpermission_loading.value = false;
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `${err.message}`,
          });
        });
      console.log(result);
    };
    const getUserId = (val) => {
      editRoleBolean.value = true;
      UserId.value = val;
    };
    const delete_Exist_Role = async (val) => {
      Swal.fire({
        title: "Enter Your Password",
        input: "password",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const res = await checkPassword({ password: result.value });
          if (res.data.success === true) {
            const resp = await deleteRole(val);
            loadData();
            console.log(resp);
            Swal.fire({
              position: 'center',
              icon: 'success',
              title: 'Role Has Been Deleted Successfully!',
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Invalid Authentication",
            });
          }
        }
      });
    };
    const currentChange = (val) => {
      pagination.value.page_number = val;
      loadData();
    };

    const itemsPerPageChange = (val) => {
      pagination.value.page_size = val;
      loadData();
    };
    const permissionByRole = async () => {
      const res = await permission_By_Role({
        role_id: assign_permission.value.role_id,
      });

      assign_permission.value.permission_id = res.data;
    };
    const unAssignPermission = async (val) => {
      permissionId.value = val;
      loading.value = true;
      const resp = await unassignPermission({ id: val });
      console.log(resp);
      loading.value = false;
      loadData();
    };

    return {
      features_list,
      userDataforUpdate,
      UserId,
      delete_Exist_Role,
      assignpermission,
      roles,
      headerConfig,
      customers,
      total,
      loading,
      permissionId,
      getUserId,
      search,
      pagination,
      cardData,
      permissions,
      assign_permission,
      role_data,
      assigpermission_loading,
      currentChange,
      permissionByRole,
      itemsPerPageChange,
      unAssignPermission,
      editRoleBolean,
      assigned_permissions
    };
  },
});
</script>
<style scoped>
li {
  list-style-type: none;
}

.container.permissions-check {
  padding-left: 30px !important;
}

.list-group-item i {
  position: absolute;
  right: 8px;
  top: 12px;
  cursor: pointer;
  visibility: hidden;
}

.list-group-item:hover i {
  visibility: visible;
}

.list-group-item span.spinner-border {
  position: absolute;
  right: 22px;
  top: 5px;
}
</style>
