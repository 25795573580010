
import { defineComponent, onMounted, ref, watch } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as _ from "lodash";
import { hideModal } from "@/core/helpers/dom";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  getFeatureList,
  permissionList,
  managePermission,
} from "@/store/api/devices";
export default defineComponent({
  name: "bin-modal",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  setup() {
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const createAPIKeyModalRef = ref<null | HTMLElement>(null);
    const binModalRef = ref<null | HTMLElement>(null);
    const feature_list = ref<any>([]);
    const permission_list = ref<any>([]);
    const manage_permission = ref<any>({
      permission_id: "",
      feature_id: "",
    });

    const validationSchema = Yup.object().shape({
      permission_id: Yup.string().required().label("permission_id").nullable(),
      feature_id: Yup.string().required().label("feature_id").nullable(),
    });
    onMounted(async () => {
      let r = await getFeatureList();
      feature_list.value = r.data;
      loadData();
    });

    // watch(
    //   () => props.customer_id,
    //   (first) => {
    //     customer_id.value = first;
    //     // discardModal()
    //   }
    // );
    const loadData = async () => {
      let re = await permissionList();
      permission_list.value = re.data;
    };

    const submit = () => {
      if (!submitButtonRef.value) {
        return;
      }

      // //Disable button
      submitButtonRef.value.disabled = true;
      // Activate indicator
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      console.log(manage_permission);
      managePermission(manage_permission.value)
        .then((res) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          loadData();
        })
        .catch((e) => {
          if (submitButtonRef.value) {
            submitButtonRef.value.disabled = false;
            submitButtonRef.value?.removeAttribute("data-kt-indicator");
          }
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `not update`,
          });
        });
    };

    const discardModal = () => {
      document.getElementById("kt_modal_bin_cancel")?.click();
    };

    const isObject = (val: boolean) => {
      return typeof val === "object";
    };

    return {
      validationSchema,
      submit,
      submitButtonRef,
      binModalRef,
      createAPIKeyModalRef,
      isObject,
      discardModal,
      feature_list,
      permission_list,
      manage_permission,
      loadData,
    };
  },
});
