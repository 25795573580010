<template>
  <!--begin::Modal - New Card-->
  <div
    class="modal fade"
    ref="submitButtonRef"
    id="feature_update_modal"
    tabindex="-1"
    aria-hidden="true"
    data-bs-focus="false"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered managefeature">
      <!--begin::Modal content-->
      <div class="modal-content">
        <!--begin::Modal header-->
        <div class="modal-header bg-primary">
          <!--begin::Modal title-->
          <h4 class="text-white text-center ms-4 fs-5 text-uppercase">
            Manage Features
          </h4>
          <!--end::Modal title-->
          <button
            type="button"
            class="btn btn-primary"
            @click="feature_form_disp = true"
          >
            + Features
          </button>
        </div>
        <!--end::Modal header-->
        <div class="card-body pt-0" style="width: 100%">
          <b-form-input
            v-if="feature_form_disp === false"
            class="my-4 w-25 float-start border border-primary"
            @input="searchData($event, searchValue)"
            placeholder="name"
            v-model="searchValue"
          ></b-form-input>
          <form action="" v-if="feature_form_disp === true">
            <div class="form-group row my-5">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label text-primary"
                >Name</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  id="inputPassword"
                  placeholder=""
                  v-model="feature_payload.name"
                />
              </div>
            </div>
            <div class="form-group row mb-5">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label text-primary"
                >Sort By</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  id="inputPassword"
                  placeholder=""
                  v-model="feature_payload.sort"
                />
              </div>
            </div>
            <button
              type="button"
              class="btn btn-primary"
              @click="add_New_Feature(feature_payload)"
            >
              Save
              <b-spinner v-if="loading" small></b-spinner>
            </button>
          </form>
          <b-table
            v-if="feature_form_disp === false"
            id="my-table"
            :items="items[currentPage]"
            class="table-borderless"
            :current-page="currentPage"
            :per-page="perPage"
            :fields="header"
          >
            <template #cell(id)="data">
              <span type="number">{{ items[currentPage][data.index].id }}</span>
            </template>
            <template #cell(name)="data">
              <b-form-input
                v-if="items[currentPage][data.index].isEdit"
                type="text"
                v-model="items[currentPage][data.index].name"
              ></b-form-input>
              <span v-else>{{ data.value }}</span>
            </template>
            <template #cell(sort)="data">
              <b-form-input
                v-if="items[currentPage][data.index].isEdit"
                type="text"
                v-model="items[currentPage][data.index].sort"
              ></b-form-input>
              <span v-else>{{ data.value }}</span>
            </template>

            <template #cell(edit)="data">
              <b-button @click="editRowHandler(data)" class="permission-action">
                <span
                  v-if="!items[currentPage][data.index].isEdit"
                  :class="`badge badge-light-success modal-badge`"
                >
                  <i class="fas fa-edit text-success"></i>
                </span>
                <span v-else
                  ><i
                    class="fas fa-check-circle text-success"
                    @click="updateValue(data)"
                  ></i
                ></span>
                <span
                  v-if="items[currentPage][data.index].isEdit"
                  :class="`badge badge-light-success modal-badge`"
                >
                  <i class="fas fa-times text-danger" aria-hidden="true"></i>
                </span>
              </b-button>

              <b-button
                class="permission-action"
                v-if="!items[currentPage][data.index].isEdit"
                @click="delete_Feature(data)"
                ><i class="fas fa-trash text-danger ml-4"></i>
              </b-button>
              <b-spinner
                v-if="loading && feature_id == data.item.id"
                class="mt-2"
                small
                label="Small Spinner"
              ></b-spinner>
            </template>
          </b-table>
          <pre></pre>
          <b-pagination
            v-if="feature_form_disp === false"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

          <p class="mt-3">Current Page: {{ currentPage }}</p>
        </div>

        <!--begin::Modal body-->

        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - New Card-->
</template>
<script>
import { defineComponent, onMounted, ref } from "@vue/runtime-core";
import {
  updateFeature,
  getFeatureList,
  deleteFeature,
  checkPassword,
  addFeature,
} from "@/store/api/devices";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
  name: "editpermission",
  setup() {
    let feature_payload = ref({
      name: "",
      sort: "",
    });
    const feature_form_disp = ref(false);
    const items = ref([]);
    const feature_list = ref([]);
    const features = ref([]);
    const searchValue = ref();
    const feature_id = ref("");
    const loading = ref(false);
    const header = ref([
      { key: "id", label: "Id" },
      { key: "name", label: "Name" },
      { key: "sort", label: "Sort" },
      { key: "edit", label: "" },
    ]);
    const perPage = ref(10);
    const currentPage = ref(1);
    const total = ref("");
    onMounted(() => {
      items.value.map((item) => ({ ...item, isEdit: false }));
      loadData();
    });
    const loadData = async () => {
      const result = await getFeatureList();
      let pag = paginate(result.data, perPage.value);
      pag.splice(0, 0, []);
      // items.value = pag.map(o => o.isEdit = false);
      items.value = pag;
      features.value = pag;
      feature_list.value = result.data;

      total.value = result.data.length;
    };
    const editRowHandler = (data) => {
      items.value[currentPage.value][data.index].isEdit =
        !items.value[currentPage.value][data.index].isEdit;
    };
    const updateValue = async (value) => {
      //{ name: v.item.name, sort: v.item.sort }
      loading.value = true;
      feature_id.value = value.item.id;
      const result = await updateFeature(value.item.id, {
        name: value.item.name,
        sort: value.item.sort,
      });

      console.log("update result", result);
      loadData();
      loading.value = false;
    };
    //const result = await deleteFeature(value.item.id);
    const delete_Feature = async (value) => {
      Swal.fire({
        title: "Enter Your Password",
        input: "password",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const res = await checkPassword({ password: result.value });
          if (res.data.success === true) {
            const resp = await deleteFeature(value.item.id);
            loadData();
            console.log(resp);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Invalid Authentication",
            });
          }
        }
      });
      // Swal.fire({
      //   title: "Are you sure?",
      //   type: "danger",
      //   showCancelButton: true,
      //   confirmButtonClass: "btn-danger",
      //   confirmButtonText: "Yes, delete it!",
      //   closeOnConfirm: false,
      // }).then(async (result) => {
      //   if (result.isConfirmed) {
      //     loading.value = true;
      //     feature_id.value = value.item.id;
      //     const resp = await deleteFeature(value.item.id);
      //     loadData();
      //     console.log(resp);
      //     loading.value = false;
      //   }
      // });
    };
    const searchData = (event, val) => {
      if (val != "") {
        items.value[currentPage.value] = feature_list.value.filter((v) => {
          let feature_name = v["name"];
          if (feature_name) {
            return feature_name.search(new RegExp(event, "i")) >= 0;
          }
        });
      } else {
        items.value = features.value;
      }
    };
    const add_New_Feature = async (val) => {
      loading.value = true;
      const res = await addFeature(val);
      Swal.fire({
        text: `${res.message}`,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      loadData();
      loading.value = false;
      feature_form_disp.value = false;
    };

    const paginate = (arr, size) => {
      return arr.reduce((acc, val, i) => {
        let idx = Math.floor(i / size);
        let page = acc[idx] || (acc[idx] = []);
        page.push(val);
        return acc;
      }, []);
    };

    return {
      feature_payload,
      header,
      editRowHandler,
      items,
      feature_list,
      updateValue,
      loading,
      delete_Feature,
      add_New_Feature,
      feature_id,
      searchData,
      features,
      searchValue,
      total,
      currentPage,
      perPage,
      feature_form_disp,
    };
  },
});
</script>
<style>
.btn.btn-secondary.permission-action {
  padding: 3px !important;
  background: #fff !important;
}

.btn.btn-secondary.permission-action:hover {
  background-color: #fff !important;
  background: #fff !important;
}

.btn.btn-secondary.permission-action:focus {
  background-color: #fff !important;
  background: #fff !important;
}

.badge-light-success.modal-badge {
  padding-top: 4px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  padding-right: 4px !important;
}

.table td:last-child {
  display: flex !important;
}

.display_none {
  display: none;
}

.display_block {
  display: block;
}
</style>
