<template>
  <!--begin::Modal - New Card-->
  <div
    class="modal fade"
    ref="submitButtonRef"
    id="permission_update_modal"
    tabindex="-1"
    aria-hidden="true"
    data-bs-focus="false"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content roundeda">
        <!--begin::Modal header-->
        <div class="modal-header bg-primary">
          <!--begin::Modal title-->
          <span class="text-white text-center ms-3 text-uppercase fs-5"
            >Manage Permissions</span
          >
          <button
            type="button"
            class="btn btn-primary"
            @click="permissionForm = true"
          >
            + Permission
          </button>
          <!--end::Modal title-->
        </div>
        <!--end::Modal header-->

        <div class="card-body pt-0" style="width: 100%">
          <!-- Add New Permission Start  -->
          <form v-if="permissionForm === true">
            <div class="form-group row my-5">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label text-primary"
                >Name</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  id="inputPassword"
                  placeholder=""
                  required
                  v-model="permission_payload.name"
                />
              </div>
            </div>
            <div class="form-group row mb-5">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label text-primary"
                >Action</label
              >
              <div class="col-sm-8">
                <input
                  type="text"
                  class="form-control"
                  id="inputPassword"
                  placeholder=""
                  v-model="permission_payload.action"
                />
                <!-- v-model="payload.action" -->
              </div>
              <div class="col-sm-1">
                <i
                  class="bi bi-info-circle fs-2 text-primary"
                  title="This action is use as permission identifier in programming.i.e ‘u-a’ refer as ‘User Add’ permission."
                ></i>
              </div>
            </div>
            <div class="form-group row">
              <label
                for="staticEmail"
                class="col-sm-3 col-form-label text-primary"
                >Features</label
              >
              <div class="col-sm-8">
                <select
                  class="form-select border border-primary form-select-solid"
                  data-control="select2"
                  data-placeholder="Select an option"
                  v-model="permission_payload.feature_id"
                >
                  <!-- v-model="payload.feature_id"
                @change="onCustomerChange" -->
                  <option value="">Select Resource</option>
                  <option
                    v-for="(feature, index) in feature_list"
                    :key="index"
                    :value="feature.id"
                  >
                    {{ feature.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-1">
                <div
                  class="mb-5 btnstlye"
                  @click="(featureForm = true), (permissionForm = false)"
                >
                  <i class="bi bi-plus fs-2"></i>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" @click="close()">
                Back
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="add_New_Permission(permission_payload)"
              >
                Save
                <b-spinner v-if="loading" small></b-spinner>
              </button>
            </div>
          </form>
          <!-- Add New Permission End  -->

          <!-- Add New Feature Start  -->
          <form action="" v-if="featureForm && permissionForm === false">
            <div class="p-5">
              <div class="form-group row mb-5">
                <label
                  for="staticEmail"
                  class="col-sm-3 col-form-label text-primary"
                  >Name</label
                >
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    placeholder=""
                    v-model="feature_payload.name"
                  />
                </div>
              </div>
              <div class="form-group row mb-5">
                <label
                  for="staticEmail"
                  class="col-sm-3 col-form-label text-primary"
                  >Sort By</label
                >
                <div class="col-sm-8">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPassword"
                    placeholder=""
                    v-model="feature_payload.sort"
                  />
                </div>
              </div>
            </div>

            <div>
              <button
                type="button"
                class="btn btn-light"
                @click="featureForm = false"
              >
                Back
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="add_New_Feature(feature_payload)"
              >
                Save
                <b-spinner v-if="loading" small></b-spinner>
              </button>
            </div>
          </form>
          <!-- Add New Feature End  -->

          <b-form-input
            v-if="permissionForm === false && featureForm === false"
            class="mt-4 mb-3 w-25 float-start boardercolors"
            @input="searchData($event, searchValue)"
            placeholder="Enter your name"
            v-model="searchValue"
          ></b-form-input>
          <b-table
            v-if="permissionForm === false && featureForm === false"
            class="table-borderless"
            id="my-table"
            :items="items[currentPage]"
            :current-page="currentPage"
            :per-page="perPage"
            :fields="header"
          >
            <template #cell(feature_id)="data">
              <b-form-select
                v-if="items[currentPage][data.index].isEdit"
                v-model="items[currentPage][data.index].feature_id"
                :options="options"
                class="mb-3"
              >
                <!-- This slot appears above the options from 'options' prop -->
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    -- Please select an option --
                  </b-form-select-option>
                </template>

                <!-- These options will appear after the ones from 'options' prop -->
                <b-form-select-option
                  v-for="(feature, i) in feature_list"
                  :key="i"
                  :value="feature.id"
                  >{{ feature.name }}</b-form-select-option
                >
              </b-form-select>
              <span v-else>{{
                items[currentPage][data.index].feature_name
              }}</span>
            </template>
            <template #cell(name)="data">
              <b-form-input
                v-if="items[currentPage][data.index].isEdit"
                type="text"
                v-model="items[currentPage][data.index].name"
              ></b-form-input>
              <span v-else>{{ data.value }}</span>
            </template>
            <template #cell(action)="data">
              <b-form-input
                v-if="items[currentPage][data.index].isEdit"
                type="text"
                v-model="items[currentPage][data.index].action"
              ></b-form-input>
              <span v-else>{{ data.value }}</span>
            </template>

            <template #cell(edit)="data" class="action-handler">
              <b-button @click="editRowHandler(data)" class="permission-action">
                <span
                  v-if="!items[currentPage][data.index].isEdit"
                  :class="`badge badge-light-success modal-badge`"
                >
                  <i class="fas fa-edit text-success"></i>
                </span>
                <span v-else :class="`badge badge-light-success modal-badge`"
                  ><i
                    @click="updatePermissionValue(data)"
                    class="fas fa-check-circle text-success fa-5x"
                  ></i
                ></span>
                <span
                  v-if="items[currentPage][data.index].isEdit"
                  :class="`badge badge-light-success modal-badge`"
                >
                  <i class="fas fa-times text-danger" aria-hidden="true"></i>
                </span>
              </b-button>
              <b-button
                class="permission-action"
                @click="delete_Permission(data)"
              >
                <span
                  v-if="!items[currentPage][data.index].isEdit"
                  :class="`badge badge-light-success modal-badge`"
                >
                  <i
                    class="fas fa-trash text-danger"
                    style="margin-bottom: 6px"
                  ></i>
                </span>
              </b-button>
              <!-- v-if="loading && feature_id == data.item.id" -->
              <b-spinner
                v-if="loading && permission_id == data.item.id"
                class="mt-2"
                small
                label="Small Spinner"
              ></b-spinner>
            </template>
          </b-table>
          <pre></pre>
          <b-pagination
            v-if="permissionForm === false && featureForm === false"
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="my-table"
          ></b-pagination>

          <p class="mt-3">Current Page: {{ currentPage }}</p>
        </div>
        <!--begin::Modal body-->

        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>

  <!--end::Modal - New Card-->
</template>
<script>
import { defineComponent, onMounted, ref, watch } from "@vue/runtime-core";
import {
  permissionList,
  getFeatureList,
  updatePermission,
  deletePermission,
  checkPassword,
  addNewPermission,
  addFeature,
} from "@/store/api/devices";
//sweetalert2/dist/sweetalert2.js
import Swal from "sweetalert2/dist/sweetalert2.js";
export default defineComponent({
  name: "editpermission",
  setup() {
    let permission_payload = ref({
      feature_id: "",
      name: "",
      action: "",
    });
    let feature_payload = ref({
      name: "",
      sort: "",
    });
    const items = ref([]);
    const permissions = ref([]);
    const permission_id = ref("");
    const searchValue = ref();
    const loading = ref(false);
    const permissionForm = ref(false);
    const featureForm = ref(false);
    const feature_list = ref([]);
    const header = ref([
      { key: "feature_id", label: "Features" },
      { key: "name", label: "Name" },
      { key: "action", label: "Action" },
      { key: "edit", label: "" },
    ]);
    const perPage = ref(10);
    const currentPage = ref(1);
    const total = ref("");
    const deleteIcon = ref(false);
    onMounted(() => {
      items.value.map((item) => ({ ...item, isEdit: false }));
      loadData();
    });
    const loadData = async () => {
      const result = await permissionList();
      let pag = paginate(result.data, perPage.value);
      // items.value =
      pag.splice(0, 0, []);
      items.value = pag;
      total.value = result.data.length;
      permissions.value = result.data;
      const feature = await getFeatureList();
      feature_list.value = feature.data;
    };
    const paginate = (arr, size) => {
      return arr.reduce((acc, val, i) => {
        let idx = Math.floor(i / size);
        let page = acc[idx] || (acc[idx] = []);
        page.push(val);
        return acc;
      }, []);
    };

    watch(currentPage.value, () => {
      console.log(currentPage.value);
    });

    const editRowHandler = async (data) => {
      items.value[currentPage.value][data.index].isEdit =
        !items.value[currentPage.value][data.index].isEdit;
    };
    const updatePermissionValue = async (data) => {
      if (items.value[currentPage.value][data.index].isEdit === true) {
        permission_id.value = data.item.id;
        loading.value = true;
        let payload = {
          feature_id: data.item.feature_id,
          name: data.item.name,
          action: data.item.action,
        };
        const result = await updatePermission(data.item.id, payload);
        console.log("now you update the value", result);
        loadData();
        loading.value = false;
      }
    };
    const delete_Permission = (data) => {
      Swal.fire({
        title: "Enter Your Password",
        input: "password",
        showCancelButton: true,
        confirmButtonText: "Confirm",
        showLoaderOnConfirm: true,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result) {
          const res = await checkPassword({ password: result.value });
          if (res.data.success === true) {
            const resp = await deletePermission(data.item.id);
            loadData();
            console.log(resp);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Invalid Authentication",
            });
          }
        }
      });
    };
    // Add new permission

    const add_New_Permission = async (val) => {
      loading.value = true;
      const re = await addNewPermission(val);
      Swal.fire({
        text: `${re.message}`,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      loading.value = false;
      if (re.success === true) permissionForm.value = false;
    };

    //End new permission

    //Add new feature
    const add_New_Feature = async (val) => {
      loading.value = true;
      const res = await addFeature(val);
      Swal.fire({
        text: `${res.message}`,
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
      loading.value = false;
      if (res.success === true) featureForm.value = false;
    };
    //End new feature

    const close = () => {
      permissionForm.value = false;
    };
    const searchData = (event, val) => {
      if (val != "") {
        items.value[currentPage.value] = permissions.value.filter((v) => {
          let feature_name = v["feature_name"];
          if (feature_name) {
            return feature_name.search(new RegExp(event, "i")) >= 0;
          }
        });
      } else {
        items.value = permissions.value;
      }
    };

    return {
      header,
      editRowHandler,
      delete_Permission,
      items,
      feature_list,
      deleteIcon,
      permission_id,
      loading,
      searchData,
      searchValue,
      total,
      currentPage,
      perPage,
      updatePermissionValue,
      permissions,
      permissionForm,
      featureForm,
      close,
      add_New_Permission,
      permission_payload,
      feature_payload,
      add_New_Feature,
    };
  },
});
</script>
<style>
.btn.btn-secondary.permission-action {
  padding: 3px !important;
  background: #fff !important;
}

.btn.btn-secondary.permission-action:hover {
  background-color: #fff !important;
  background: #fff !important;
}

.btn.btn-secondary.permission-action:focus {
  background-color: #fff !important;
  background: #fff !important;
}

.badge-light-success.modal-badge {
  padding-top: 4px !important;
  padding-bottom: 10px !important;
  padding-left: 10px !important;
  padding-right: 4px !important;
}

.table td:last-child {
  display: flex !important;
}

.display_none {
  display: none;
}

.display_block {
  display: block;
}

.btnstlye {
  width: 30px;
  height: 30px;
  background: #009ef7;
  cursor: pointer;
  border-radius: 50px;
  position: relative;
}

.bi-plus {
  position: absolute;
  color: white;
  top: 2px;
  left: 2px;
}

.boardercolors {
  border-color: #009ef7;
}
</style>
